import NiceModal from '@ebay/nice-modal-react'
import dynamic from 'next/dynamic'
import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'

import { PageMetaData } from '@/components/atomic/PageMetaData'
import { Header } from '@/components/styleguide/Header'
import { DEALS_UNDER_300, TopDeals } from '@/components/styleguide/Homepage/Sections/TopDeals'
import { HeroBackground } from '@/components/views/HomePage/HeroBackground'
import { Search } from '@/components/views/HomePage/Search'
import { SearchForm } from '@/components/views/SearchForm'
import { constants, FILTER_VEHICLE_TYPES_CARS } from '@/lib/constants'
import { useModalData } from '@/lib/hooks/useModalData'
import { ROUTES } from '@/lib/routes'
import { getPageContentListService } from '@/lib/services/pageService'
import { submitSearchService } from '@/lib/services/searchService'
import { getBestDealsByBodyStyleService } from '@/lib/services/vehicleService'
import { storageAvailable } from '@/lib/utilities/system'
import { INITIAL_STATE } from '@/store/reducers/search'
import { Theme } from '@/types/system'


const CollectionsCarousel = dynamic(
  () => import('@/components/styleguide/Homepage/Sections/Collections'),
)
const Brands = dynamic(() => import('@/components/styleguide/Homepage/Sections/Brands'))
const Grid = dynamic(() => import('@/components/views/HomePage/Grid'))
const Cards = dynamic(() => import('@/components/views/HomePage/Cards'))
const LocoScore = dynamic(() => import('@/components/views/HomePage/ValueScore'))
const HowDoesItWork = dynamic(() => import('@/components/views/HomePage/HowDoesItWork'))
const Faqs = dynamic(() => import('@/components/views/HomePage/Faqs'))
const ReferAFriend = dynamic(() => import('@/components/views/HomePage/ReferAFriend'))
const Testimonials = dynamic(() => import('@/components/views/HomePage/Testimonials'))
const NewsletterFormModal = dynamic(() => import('@/components/modals/NewsletterFormModal'))

const HomePage = ({ heroVehicle, faqList, dealList, secondDealList, dealCount }) => {
  const areThereActiveModals = useModalData()
  const { ref, inView } = useInView()
  const [isVans] = useState(false)

  useEffect(() => {
    if (
      storageAvailable('localStorage') &&
      !areThereActiveModals &&
      inView &&
      !window.localStorage.getItem(constants.localStorageKey.newsletterPopup)
    ) {
      NiceModal.show(NewsletterFormModal)
    }
  }, [areThereActiveModals, inView])

  return (
    <main className="container-fluid container--content-homepage is-cars">
      <PageMetaData
        title={constants.metaData.title}
        description={constants.metaData.description}
        pageUrl={ROUTES.homePage.url}
      />
      <HeroBackground isVans={isVans}>
        <Header
          theme={Theme.dark}
          backgroundColor="transparent"
        />
        <Search isVans={isVans}>
          <SearchForm
            hasRedirectToLandingPage
            initialVehicleType={FILTER_VEHICLE_TYPES_CARS}
          />
        </Search>
      </HeroBackground>
      <Brands isVans={isVans} />
      <TopDeals
        dealList={dealList}
        isVans={isVans}
      />
      <div ref={ref}></div>
      <CollectionsCarousel />
      <TopDeals
        dealList={secondDealList}
        isVans={isVans}
        type={DEALS_UNDER_300}
        isVariant
      />
      <Grid
        isVans={isVans}
        dealCount={dealCount}
      />
      <LocoScore
        deal={heroVehicle}
        isVans={isVans}
      />
      <Cards
        isVans={isVans}
        dealCount={dealCount}
      />
      <Testimonials />
      <HowDoesItWork isVans={isVans} />
      <Faqs faqList={faqList} />
      <ReferAFriend isVans={isVans} />
    </main>
  )
}

export default HomePage

export const getServerSideProps = async () => {
  let dealList
  let heroVehicle
  let faqList
  let secondDealList
  let dealCount

  try {
    try {
      const response = await getBestDealsByBodyStyleService({
        vehicleType: FILTER_VEHICLE_TYPES_CARS,
      })
      heroVehicle = response['In Stock SUV (Medium)']
    } catch (err) {
      heroVehicle = null
    }

    try {
      const response = await submitSearchService(INITIAL_STATE.searchParameters)

      dealList = response.results
    } catch (err) {
      dealList = null
    }

    try {
      const response = await submitSearchService({
        ...INITIAL_STATE.searchParameters,
        monthlyPaymentMax: 250,
      })

      secondDealList = response.results
    } catch (err) {
      secondDealList = null
    }

    try {
      const response = await submitSearchService({
        ...INITIAL_STATE.searchParameters,
        monthlyPaymentMax: '',
        mileages: [],
        leaseTypes: [1, 2],
        vehicleTypes: [1, 2],
      })

      dealCount = response.pricesTotal
    } catch (err) {
      dealCount = 0
    }

    try {
      faqList = await getPageContentListService({
        type: 'FAQ_HOMEPAGE',
        page: '1',
      })
    } catch (err) {
      faqList = null
    }

    return {
      props: {
        dealList,
        heroVehicle,
        faqList,
        secondDealList,
        dealCount,
      },
    }
  } catch (err) {
    return {
      props: {
        heroVehicle: null,
        faqList: [],
      },
    }
  }
}
